import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
`

const MFLogoImg = styled.img`
  margin-bottom: 0;
  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        siteOptions {
          siteOptions {
            logo {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const { wp } = data

  return (
    <LogoWrap as={Link} to="/">
      <MFLogoImg
        src={wp.siteOptions.siteOptions.logo.localFile.publicURL}
        alt={wp.siteOptions.siteOptions.logo.altText}
        height="42"
        width="205"
      />
    </LogoWrap>
  )
}

export default Logo
