import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const CopyrightBar = styled.div`
  background: #25333d;
  color: #edd9af;
  text-align: center;
  margin: 0;
  padding: 20px;
  & p {
    padding-bottom: 0;
  }
`

const Copyright = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFooter {
          ACFFooterOptions {
            footerCopyrightText
          }
        }
      }
    }
  `)

  const { wp } = data

  return (
    <CopyrightBar>
      {parse(`${wp.acfOptionsFooter.ACFFooterOptions.footerCopyrightText}`)}
    </CopyrightBar>
  )
}

export default Copyright
