import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const FooterRow = styled.div`
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const FooterHeading = styled.h2`
  font-size: 24px;
  font-weight: 600;
`

const FooterText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #eff1ef;
  & a {
    font-size: 18px;
    font-weight: 400;
    color: #eff1ef;
    text-decoration: none;
  }
  & p {
    font-size: 18px;
    font-weight: 400;
    color: #eff1ef;
    text-decoration: none;
  }
`

const FooterRows = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFooter {
          ACFFooterOptions {
            rowOne {
              rowOneText
              rowOneHeading
            }
            rowTwo {
              rowTwoHeading
              rowTwoText
            }
            rowThree {
              rowThreeHeading
              rowThreeText
            }
          }
        }
      }
    }
  `)

  const { wp } = data

  return (
    <>
      <FooterRow>
        <FooterHeading>
          {wp.acfOptionsFooter.ACFFooterOptions.rowOne.rowOneHeading}
        </FooterHeading>
        <FooterText>
          {parse(`${wp.acfOptionsFooter.ACFFooterOptions.rowOne.rowOneText}`)}
        </FooterText>
      </FooterRow>
      <FooterRow>
        <FooterHeading>
          {wp.acfOptionsFooter.ACFFooterOptions.rowTwo.rowTwoHeading}
        </FooterHeading>
        <FooterText>
          {parse(`${wp.acfOptionsFooter.ACFFooterOptions.rowTwo.rowTwoText}`)}
        </FooterText>
      </FooterRow>
      <FooterRow>
        <FooterHeading>
          {wp.acfOptionsFooter.ACFFooterOptions.rowThree.rowThreeHeading}
        </FooterHeading>
        <FooterText>
          {parse(
            `${wp.acfOptionsFooter.ACFFooterOptions.rowThree.rowThreeText}`
          )}
        </FooterText>
      </FooterRow>
    </>
  )
}

export default FooterRows
