import React from "react"
import styled from "styled-components"
import Wrapper from "../StyledElements/wrapper"
import FooterRows from "./FooterRows"
import Copyright from "./Copyright"

const Footer = styled.footer`
  background: #455260;
  color: #eff1ef;
  margin: 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 3fr));
  justify-content: center;
  align-content: center;
  padding: 3rem 20px;
`

const footer = () => {
  return (
    <Footer>
      <Wrapper>
        <Grid>
          <FooterRows />
        </Grid>
      </Wrapper>
      <Copyright />
    </Footer>
  )
}

export default footer
