import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Link } from "gatsby";
import Wave from "../../images/wave.svg"

const StyledModalContent = styled.div`
  background-color: #25333d;
  border: 5px solid #c6af6d; 
  padding: 30px;
  margin: 12px;
  text-align: center;
  box-shadow: 0 0 0 14px #25333d;
  box-sizing: border-box;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    z-index: -1;
    box-shadow: 0 0 0 14px #25333d;
  }

  h2 {
    font-size: 72px;
    color: rgb(239, 241, 239);
    margin-bottom: 20px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 54px;
    }
  }

  p {
    font-size: 20px;
    color: rgb(239, 241, 239);
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: normal;

    @media (max-width: 768px) {
      font-size: 17px;
    }
  }

  button {
    border-radius: 4px;
    background: #c6af6d;
    padding: 10px 22px;
    color: #eff1ef;
    font-size: 26px;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-top: 20px;
    margin-left: 24px;

    &:hover {
      background-color: #937B39;
    }

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .secondaryText {
    font-size: 16px;
    color: rgb(239, 241, 239);
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  .closeButton {
    position: absolute;
    right: 15px;
    top: 0px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:before, &:after {
      content: '';
      position: absolute;
      width: 22px;
      height: 3px;
      background-color: #E0E0E0;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
`;

const StyledWave = styled.img`
  width: 125px;
  height: auto;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const KlikLink = styled(Link)`
  color: rgb(239, 241, 239);
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '80%',
    maxWidth: '800px',
  },
  overlay: {
    backgroundColor: 'rgba(37, 51, 61, 0.75)',
    border: 'none'
  }
};

const isBrowser = typeof window !== 'undefined';

Modal.setAppElement('#___gatsby');

const NoticePopup = () => {
  const [isModalOpen, setIsModalOpen] = useState(() => {
    if (isBrowser) {
      return !localStorage.getItem('hasClickedSecondaryText');
    }
    return true;
  });

  const handleClose = () => {
    localStorage.setItem('hasClickedSecondaryText', 'true');
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      contentLabel="Notice Popup"
    >
      <StyledModalContent>
        <StyledWave src={Wave} alt="wave icon" />
        <button className="closeButton" onClick={handleClose}></button>
        <h2>Hej!</h2>
        <p>Vi anvender IKKE marketing- eller trackingcookies på Marketingfabrikken.dk, da vi IKKE annoncerer for os selv. Vi lever af anbefalinger, hvilket sikkert også er måden du har fundet os på.</p>
        <button onClick={handleClose}>Fedt!<br /><p style={{fontSize: "12px"}}>(Fortsæt på websitet)</p></button>
        <div className="secondaryText" onClick={handleClose}><KlikLink to="https://marketingfabrikken.dk/kontakt/" onClick={handleClose}>Eller klik her, hvis du gerne vil sige hej til os</KlikLink></div>
      </StyledModalContent>
    </Modal>
  );
};

export default NoticePopup;
